$step: 2; // Step size in pixels

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.w-fit-content {
  width: fit-content;
}

.p-absolute {
  position: absolute;
}

.d-none {
  display: none;
}

.d-flex {
  display: flex;
}

.flex-1 {
  flex: 1;
}

.flex-grow-1 {
  flex-grow: 1;
}

.d-flex-column {
  display: flex;
  flex-direction: column;
}

.justify-content-start {
  justify-content: flex-start;
}

.justify-content-end {
  justify-content: flex-end;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-space-between {
  justify-content: space-between;
}

.justify-content-space-evenly {
  justify-content: space-evenly;
}

.align-items-center {
  align-items: center;
}

.align-items-start {
  align-items: flex-start;
}

.align-items-end {
  align-items: flex-end;
}

.flex-center {
  @extend .d-flex;
  @extend .justify-content-center;
}

.flex-center-box {
  @extend .d-flex;
  @extend .justify-content-center;
  @extend .align-items-center;
}

.flex-wrap {
  flex-wrap: wrap;
}

.d-block {
  display: block;
}

.no-bg-paginator {
  .p-paginator {
    background-color: transparent;
  }
}

// Generate margin utilities
@for $i from 0 through 50 {
  .m-#{$i * $step} {
    margin: #{$i * $step}px;
  }
  .mt-#{$i * $step} {
    margin-top: #{$i * $step}px;
  }
  .mb-#{$i * $step} {
    margin-bottom: #{$i * $step}px;
  }
  .ml-#{$i * $step} {
    margin-left: #{$i * $step}px;
  }
  .mr-#{$i * $step} {
    margin-right: #{$i * $step}px;
  }
}

// Generate padding utilities
@for $i from 0 through 50 {
  .p-#{$i * $step} {
    padding: #{$i * $step}px;
  }
  .pt-#{$i * $step} {
    padding-top: #{$i * $step}px;
  }
  .pb-#{$i * $step} {
    padding-bottom: #{$i * $step}px;
  }
  .pl-#{$i * $step} {
    padding-left: #{$i * $step}px;
  }
  .pr-#{$i * $step} {
    padding-right: #{$i * $step}px;
  }
}

// Generate gap utilities
@for $i from 0 through 50 {
  .gap-#{$i * $step} {
    gap: #{$i * $step}px;
  }
}

.row-gap-8 {
  row-gap: 8px;
}

.row-gap-24 {
  row-gap: 24px;
}

.flex-fill {
  flex: 1;
}

.cursor-default {
  cursor: default;
}

.no-border {
  .p-inputtext {
    border-color: transparent;
  }

  &.p-inputtext {
    border-color: transparent;
  }
}

.input-unset-border {
  border: unset;

  &.p-inputtext:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: unset;
    border-color: unset;
  }
}

.full-width-inputtext {
  .p-calendar {
    width: 100%;
  }
}

.db-input-error {
  color: #ff5455;
  margin-top: 4px;
}

.position-rel {
  position: relative;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.text-center {
  text-align: center;
}

.sticky-parent-container {
  overflow: visible;
}

.list-style-none {
  list-style: none;
}
